import React from 'react'

import { Columns, ColumnsInner, Main, Wrapper } from '@te-digi/styleguide'

import { CodeBox } from '../../components/CodeBox'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Columns, ColumnsInner, Main, Wrapper"
    components={[
      { component: Columns },
      { component: ColumnsInner },
      { component: Main },
      { component: Wrapper }
    ]}
  >
    <Section title="Tyypillinen sivurakenne">
      <SectionHeading>React</SectionHeading>
      <CodeBox language="tsx">
        {`<>
  <SkipLink />
  <Wrapper>
    <HeaderBackground>
      <Header />
    </HeaderBackground>
    <Columns
      layout="sidebar"
      sidebar={…} // When using multi-column layout
    >
      <Main>
        <Breadcrumb>…</Breadcrumb>
        <Heading>…</Heading>
        …
      </Main>
    </Columns>
    <Footer />
  </Wrapper>
</>`}
      </CodeBox>
      <SectionHeading>HTML</SectionHeading>
      <CodeBox language="html">
        {`<a class="btn btn-outline-primary skip-link" href="#content">…</a>
<div id="page-wrapper">
  <div class="page-header-background">
    <header class="page-header">…</header>
  </div>
  <div class="columns columns-sidebar">
    <!-- Use <div> wrapper for sidebar -->
    <div>…</div>
    <main id="content" role="main">
      <nav aria-label="…">
        <ol class="breadcrumb">…</ol>
      </nav>
      <h1>…</h1>
    </main>
  </div>
  <footer class="page-footer">…</footer>
</div>`}
      </CodeBox>
    </Section>
  </Content>
)

export default Page
